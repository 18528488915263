export const friendSexFlowData = [
  {
    template: `ContentScreen`,
    id: `2.1.1-understanding-consent`,

    data: {
      textLeft: `<h2>Understanding consent</h2>
             <p>Sex is supposed to be fun and enjoyable, but sometimes sexual experiences happen that one person doesn’t feel good about and didn’t want.</p>
             <p>Legally, any unwanted behaviour of a sexual nature that causes fear, humiliation or intimidation is considered to be sexual assault.</p><p>In other words, if someone does something to you that you didn’t or couldn’t consent to (provide free agreement), it’s a crime.</p>
             `,
      textRight: `<p>Sexual assault includes:</p>
             <ul>
             <li>Rape (with or without physical violence);</li>
             <li>Forcing someone to have oral sex (give or receive);</li>
             <li>Penetrating someone with fingers or other objects;</li>
             <li>Any other uninvited sexual behaviour that makes you feel uncomfortable, harassed or afraid.</li>
            </ul>
            <p>A person can’t give free agreement if they’re being <span>threatened</span>, or if they’re <span>asleep, drunk or affected by substances.</span></p>`,
    },
  },
  {
    template: `ContentScreen`,
    id: `2.1.1-understanding-consent2`,

    data: {
      textLeft: `<h2>Understanding consent</h2>
             <p>We often hear about ‘No means no!’ but sometimes consent is complicated. Sometimes people might feel like they can’t say no, or that saying no would be unsafe, so instead they say nothing.</p>
             <p>Silence is not <span>the same as consent</span>. Just because someone didn’t say ‘No’ or didn’t fight back doesn’t mean they consented.</p>
             `,
      textRight: `<p>On this website we are talking about sexual experiences someone might have had as an adult (over the age of 16) with someone they were not in a relationship with.</p><p>If you have experienced sexual abuse as a child (under 16), this website might help <a href://www.blueknot.org.au.>www.blueknot.org.au</a>.</p>
      <p>If someone has had an unwanted sexual experience in a relationship, there is a relationships section of this website that might be more useful.</p>
             `,
    },
  },
  {
    template: `ContentScreen`,
    id: `2.3.1.1-What-can-I-do`,

    data: {
      textLeft: `<h2>What can I do?</h2>
             <p>What should I do if a friend discloses a sexual assault to me?</p>
             <p>How you respond to a friend who discloses a sexual assault can have a big impact on their wellbeing and recovery. It’s a big responsibility, but it’s easier than you think.</p><p>The most important thing you can do is <span>believe them!</span> Never blame them or imply that they did anything wrong.<span> It is never the victim’s fault</span> when a sexual assault happens.</p>
             `,
      textRight: `<p>There’s a useful mnemonic called LIVES that can help you remember what to do:</p>
      <ul>
       <li><span>L</span>isten to them (without judging or telling them what to do)</li>
       <li><span>I</span>nquire about their needs (ask what THEY want and need)</li>
       <li><span>V</span>alidate their experiences (tell them this is something that does happen to students and that it is not acceptable)</li>
       <li><span>E</span>nhance safety (check that they are not in any danger (e.g. from a stalker), discuss whether there is anything else they can do to stay safer)</li>
       <li><span>S</span>upport and follow up (let your friend know that you are there for them, and check-in regularly to make sure they’re OK.</li>
      </ul>
      <p>A bit later, this website will suggest some helpful resources you can give your friend.</p>
             `,
    },
  },
  {
    template: `ContentScreen`,
    id: `2.3.1.1-What-can-I-do`,

    data: {
      textLeft: `<h2>What can I do?</h2>
             <p>Your friend may not know what to do next after being sexually assaulted.</p><p>Depending on how long ago the assault happened, and what their wishes are, you can suggest the following:</p>`,
      textRight: `
      <ul>
      <li>If they want to report the assault – Offer to go with them to the police station to file a report. If the assault happened within 5 days, the police may take your friend to the hospital for a forensic examination to collect evidence about the assault. This will only happen if your friend consents.</li>
      <li>If they don’t want to report the assault – Ask if they have seen a doctor to get checked out and make sure they’re physically OK. If not, offer to take them. It’s important to get tested for sexually transmitted infections as well. If your friend is a woman and the assault happened within 72 hours they can get emergency contraception (the morning after pill).
      </ul>
      <p>A bit later this website will suggest more helpful resources.</p>
             `,
    },
  },
  {
    template: `ContentScreen`,
    id: `2.3.1.2-feelings`,

    data: {
      textLeft: `<h2>Feelings</h2>
             <p>Supporting a friend who has been sexually assaulted can be really difficult and stressful. You might feel a range of emotions including:</p>
             <ul>
             <li>Frustration at your friend’s lack of action</li>
             <li>Guilt that you can’t help them</li>
             <li>Worry and anxiety about your friend’s wellbeing</li>
             <li>A sense of responsibility for your friend</li>
             </ul>
             `,
      textRight: `
      <p>All of these feelings are normal. It’s important to look after yourself as well during this time.</p><p>If you feel like you’re struggling, the university counselling service is free and confidential.</p>
             `,
    },
  },
  {
    template: `VideoScreen`,
    id: `2.3.1.2-feelings-excercise`,
    data: {},
  },
]
