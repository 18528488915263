const sliderCardsData = [
  {
    primaryText: `How unhealthy do you think your relationship is with your partner or ex-partner?`,
    options: [
      `Very unhealthy or abusive`,
      `Somewhat unhealthy`,
      `Healthy Relationship`,
    ],
  },
  {
    primaryText: `How unsafe do you feel in your relationship with your partner or ex-partner?`,
    options: [`Very unsafe`, `Somewhat unsafe`, `Safe`],
  },
  {
    primaryText: `How afraid do you feel in your relationship with your partner or ex-partner?`,
    options: [`Very afraid`, `Somewhat afraid`, `Not afraid`],
  },
]

export const CompositeScaleCardsData = [
  {
    primaryText: `My partner told me that I wasn’t good enough.`,
  },
  {
    primaryText: `Kept me from medical care.`,
  },
  {
    primaryText: `Followed me`,
  },
  {
    primaryText: `Tried to turn my family, friends
    or children against me`,
  },
  {
    primaryText: `Locked me in the bedroom`,
  },
  {
    primaryText: `Slapped me`,
  },
  {
    primaryText: `Forced me to have sex`,
  },
  {
    primaryText: `Told me that I was ugly`,
  },
  {
    primaryText: `Tried to keep me from seeing or talking to my family`,
  },
  {
    primaryText: `Threw me`,
  },
  {
    primaryText: `Hung around outside my house`,
  },
  {
    primaryText: `Blamed me for causing their violent behaviour`,
  },
  {
    primaryText: `Harassed me over the telephone`,
  },
  {
    primaryText: `Shook me`,
  },
  {
    primaryText: `Tried to force me to have sex`,
  },
  {
    primaryText: `Harassed me at work`,
  },
  {
    primaryText: `Pushed, grabbed or shoved me`,
  },
  {
    primaryText: `Used a knife or gun or other weapon`,
  },
  {
    primaryText: `Became upset if
    dinner/housework was not
    done when they thought it
    should be.`,
  },
  {
    primaryText: `Told me that I was crazy`,
  },
  {
    primaryText: `Told me that no one would
    ever want me`,
  },
  {
    primaryText: `Took my wallet and left me
    stranded`,
  },
  {
    primaryText: `Hit or tried to hit me with
    something`,
  },
  {
    primaryText: `Did not want me to socialise
    with my friends`,
  },
  {
    primaryText: `Put foreign objects in my
    vagina or anus`,
  },
  {
    primaryText: `Refused to let me work
    outside the home`,
  },
  {
    primaryText: `Kicked me, bit me or hit me
    with a fist`,
  },
  {
    primaryText: `Tried to convince my friends,
    family or children that I was
    crazy`,
  },
  {
    primaryText: `Told me that I was stupid`,
  },
  {
    primaryText: `Beat me up`,
  },
]

const TwoColumnCheckboxScreenOptions = [
  `General Practitioner`,
  `Friends or family`,
  `Police`,
  `Uni counsellor`,
  `Domestic violence service`,
  `E-Safety Commissioner`,
  `Safer Community Program`,
  `No one or not sure`,
]

const OnlineBehaviourCheckboxOptions = [
  `Threatened online to physically hurt you, your family or friends`,
  `Monitored where you were via tracking software`,
  `Pressured you online to engage in sexual acts`,
  `Pressured you online to send nude image(s) of yourself`,
  `Threatened to share a nude photo or video of you online`,
  `Shared a nude photo or video of you online without your permission`,
  `Made you disclose online conversation(s) you had with another person(s) to them`,
  `Pressured you to share your digital password(s) with them`,
  `Logged onto your digital device (computer, phone, tablet etc.) without your permission`,
]

const FeelingsYesNoCardOptions = [
  {
    primaryText: `You might be unsure about whether sexual things happening in your relationship are normal`,
  },
  {
    primaryText: `You may wonder if what you are experiencing is ‘bad enough’ to need help`,
  },
  {
    primaryText: `You might love your partner but be scared of them at the same time`,
  },
  {
    primaryText: `You might feel guilty or ashamed for staying in a relationship that is unhealthy`,
  },
  {
    primaryText: `You might feel like you are the only one who understands and can help your partner`,
  },
  {
    primaryText: `You may be worried about what will happen if you try to talk to your partner about their behaviour `,
  },
  {
    primaryText: `You may feel it is safer to stay in the relationship or worry that leaving might make your partner’s behaviour worse.`,
  },
  {
    primaryText: `You may feel humiliated at being a victim of abuse`,
  },
  {
    primaryText: `You might feel frightened for your own safety and wellbeing`,
  },
  {
    primaryText: `You might feel like you can’t escape behaviours that are happening online`,
  },
  {
    primaryText: `You may hope that your partner will change their behaviour`,
  },
]

export const TalkingAboutItYesNoCardsData = [
  {
    primaryText: `Has any physical violence increased in severity or frequency in the last year?`,
  },
  {
    primaryText: `Has your partner recently threatened or attempted suicide or self harm?`,
  },
  {
    primaryText: `Misused alcohol drugs or other substances?`,
  },
  {
    primaryText: `Followed you, repeatedly harassed or messaged/emailed you? `,
  },
  {
    primaryText: `Been obsessively jealous or possessive of you?`,
  },
  {
    primaryText: `Threatened or used a weapon against you?`,
  },
  {
    primaryText: `Assaulted you when you were pregnant?`,
  },
  { primaryText: `Tried to choke or strangle you?` },
  {
    primaryText: `Forced you to have sex or participate in sexual acts when you did not wish to?`,
  },
  { primaryText: `Threatened to kill you?` },
  {
    primaryText: `Do you believe it is possible they could kill or seriously harm you?`,
  },
]

export const victimRelationshipFlowData = [
  {
    template: `ContentScreen`,
    id: `2.1.2-health-relationship-content`,
    data: {
      textLeft: `
      <h2>What is a healthy relationship?</h2>
      <p>Sometimes, it can be difficult to tell whether a relationship is healthy or unhealthy, especially when you don’t have anything to compare it to.</p>`,
      textRight: `
      <p>Generally, a healthy relationship involves:</p>
      <ul>
      <li>Mutual trust</li>
      <li>Support and open communication</li>
      <li>Commitment and honesty</li>
      <li>Respect</li>
      <li>Freedom to make your own decisions</li>

      </ul>
      <p>An unhealthy relationship involves:</p>
      <ul>
      <li>Unsupportive or disrespectful behaviour</li>
      <li>Pain, fear or harm</li>
      <li>Controlling behavior</li>
      </ul>`,
    },
  },
  {
    template: `SliderCardsScreen`,
    id: `2.1.2-healthy-relationship-slider-cards`,
    data: {
      textLeft: `
      <h2>What is a healthy relationship?</h2>
      <p>Even if you sometimes argue or fight, this does not necessarily mean your relationship is unhealthy.<p>However, when one person is feeling unsafe or afraid in the relationship, this is usually a sign that there are serious problems.</p>
      `,
      textRight: `<p>It can be helpful to assess your relationship using the scales below.</p><p class='instructions'>Drag the scale from left to right to make your selection.</p>`,
      slidersData: sliderCardsData,
    },
  },
  {
    template: `CompositeScaleCardsScreen`,
    id: `2.1.2.1-partner-behaviours-composite-scales`,
    data: {
      popUp: {
        tall: true,
        type: `feedback`,
        msg1: `<h2>Feedback</h2><p>It sounds like you have not experienced any of the abusive behaviours we've listed, so we can't give you feedback on this section. There may be other things happening in your relationship that are making you feel unsafe. It's always best to trust your instincts.</p><p>Answering some of these questions can be confronting. Remember to take a break if you need one. To talk to someone urgently, please call 1800-RESPECT (<a href="tel:1800 737 732">1800 737 732</a>).</p>
`,
        msg2: `<h2>Feedback</h2><p>You seem to have experienced serious abusive behaviours from your partner. It is not acceptable for these things to happen in relationships. It is probably affecting your mental and physical health and possibly your studies as well.</p><p>A bit later, this website will suggest some things you could try to help you look after yourself during this stressful time.</p>    <p>Answering some of these questions can be confronting. Remember to take a break if you need one. To talk to someone urgently, please call 1800-RESPECT (<a href="tel:1800 737 732">1800 737 732</a>).</p>
`,
        msg3: `<h2>Feedback</h2><p>You seem to have experienced a combination of physical and emotional behaviours from your partner that are abusive. It is not acceptable for these things to happen in relationships. It is likely to be affecting your mental and physical health and possibly your studies as well.</p><p>A bit later, this website will suggest some things you could try to help you look after yourself during this stressful time.</p>    <p>Answering some of these questions can be confronting. Remember to take a break if you need one. To talk to someone urgently, please call 1800-RESPECT (<a href="tel:1800 737 732">1800 737 732</a>).</p>
`,
        msg4: `<h2>Feedback</h2><p>You seem to have experienced physical actions from your partner that are abusive. It is not acceptable for this to happen in relationships. A bit later, this website will suggest some things you could try to help you look after yourself during this stressful time.</p>    <p>Answering some of these questions can be confronting. Remember to take a break if you need one. To talk to someone urgently, please call 1800-RESPECT (<a href="tel:1800 737 732">1800 737 732</a>).</p>
`,
        msg5: `<h2>Feedback</h2><p>You seem to have experienced emotional actions from your partner that are abusive. It is not acceptable for this to happen in relationships. A bit later, this website will suggest some things you could try to help you look after yourself during this stressful time.</p>    <p>Answering some of these questions can be confronting. Remember to take a break if you need one. To talk to someone urgently, please call 1800-RESPECT (<a href="tel:1800 737 732">1800 737 732</a>).</p>
`,
      },
      heading: `Partner Behaviours`,
      textLeft: `<p>
      Sometimes we might think a relationship is healthy, but when we look
      at the different behaviours that might be happening, we realise
      there are problems.
    </p>
    <p>
      Here’s a list of behaviours that people sometimes experience from a
      partner or ex-partner.
    </p>`,
      textRight: `<p class='margin'>
      We would like to know which of these you have experienced over the
      <span class='underline'>last 12 months</span>, and how often
      it happened.
    </p>`,
      cardsData: CompositeScaleCardsData,
    },
  },
  {
    template: `CenteredContentScreen`,
    id: `2.1.2.3-partner-behaviours-content`,
    data: {
      heading: `Emotional Abuse`,
      body1: `
        <p>People often think that an abusive relationship involves hitting, kicking and other physical violence. But relationships can be abusive even if there is no physical violence at all. Emotional and verbal abuse is common and can be incredibly harmful.</p>
        <p>In includes things like:</p>
        <ul>
          <li>Controlling behavior – telling you what you can and can’t do, where you can go, who you can see, how you should dress or act</li>
        	<li>Putting you down all the time – for example calling you ugly, stupid, or worthless</li>
        	<li>Using verbal aggression and intimidation</li>
        	<li>Forcing you to show them your phone or email so they can see who has been calling, texting or emailing you</li>
        	<li>Public embarrassment or deliberate criticism in front of friends, colleagues or family</li>
        	<li>Blackmail, manipulation, or ‘freezing out’ as a way of punishing you</li>
        	<li>Denying that things are happening or re-writing history (‘gaslighting’)</li>
        	<li>Stalking or following you</li>
        	<li>Using your visa status as a threat to get you to do what they want</li>
        	<li>Trying to manipulate you into getting pregnant or having an abortion</li>
        </ul>
        `,
    },
  },
  {
    template: `ContentScreen`,
    id: `victim-sex-in-relationships`,
    data: {
      textLeft: `
      <h2>Sex in relationships</h2>
      <p>Sometimes in relationships, things can happen that you haven’t consented to and didn’t want to do. This can be incredibly confusing, overwhelming and hurtful. For example, you may have experienced things like:</p>
      <ul>
      <li>A partner continually pressuring you for sex, even when you say 'No';</li>
      <li>A partner forcing you to do something sexual that you didn't agree to;</li>
      <li>A partner trying to make you feel guilty for not wanting sex, sulking or 'freezing you out';</li>
      <li>A partner penetrating you while you're asleep;</li>
      <li>A partner threatening to hurt you if you don't have sex with them.</li>
      </ul>
      `,
      textRight: `<p>All of these things are forms of sexual assault. Just because you’re in a relationship with someone, doesn’t mean they have a right to have sex with you. In a healthy, supportive relationship, partners respect each other's sexual autonomy and their right to say 'No'. If that isn't happening, you might want to talk to someone about what’s going on in your relationship.</p>`,
    },
  },
  {
    template: `ContentScreen`,
    id: `2.1.2.4-online-behaviours-pre`,
    data: {
      textLeft: `
      <h2>Online behaviours</h2>
      <p>Sometimes things happen online that make you feel scared,
embarrassed or unsafe.</p>
`,
      textRight: `<p>This section of the website is focusing on
things a current or ex-partner might have
done to you online or using a digital device (e.g. phone).</p>`,
    },
  },
  {
    template: `CheckboxScreen`,
    id: `2.1.2.5-online-behaviours-checkbox`,

    data: {
      popUp: {
        tall: true,
        type: `feedback`,
        msg1: `<h2>Feedback</h2>
                <p>It sounds like your partner is using a digital device to intimidate, harass or control you.</p>
               <p>This is a form of abuse, and it is likely to be affecting your physical and mental health, even though it’s happening online. It is not acceptable for these things to happen in relationships.</p>
               <p>A bit later this website will suggest some things to try that might help you look after yourself, and take action to feel safer.</p>
        `,
        msg2: `<h2>Feedback</h2>
               <p>It sounds like you may be experiencing what is called “image-based abuse”.</p>
        <p>Image-based abuse happens when someone shares (or threatens to share) a naked or intimate image of you online without your consent. It is a type of sexual violence, and it is illegal.</p>
        <p>There are some things you can do to get the images removed and to take action against the person responsible if you wish. At the end of this website we will suggest some organisations who can help.</p>
        `,
      },
      heading: `Online Behaviours`,
      textRight: `<p>Has a partner ever done any of these things.</p>
      <span>Please choose all the options that apply.</span>`,
      columns: 3,
      options: OnlineBehaviourCheckboxOptions,
    },
  },

  {
    template: `YesNoCardsScreen`,
    id: `2.1.2.7-feelings-yes-no-cards`,
    data: {
      textLeft: `<h2>Feelings</h2>
      <p>You may have complicated feelings about your relatiohship.</p>
      <p>Select <span>'yes'</span> if you are feeling any of these.</p>`,
      cardsData: FeelingsYesNoCardOptions,
    },
  },
  {
    template: `ContentScreen`,
    id: `2.1.2.7-feelings-feedback`,
    data: {
      textLeft: `
        <p>These feelings are common and normal. Being in a relationship with someone who hurts you physically, emotionally, or sexually can be extremely confusing. A good piece of advice is to ‘trust your instincts’. In most cases, if you feel that something isn’t right, it’s probably a good idea to talk to someone.</p>
        `,
      textRight: `<p>A bit later, this website will suggest who you can talk to about your feelings (anonymously if needed), and can help you to figure out how urgent it is for you to get some help.</p>`,
    },
  },
  {
    template: `VideoScreen`,
    id: `2.1.2.7-video-1`,
    data: {},
  },
  {
    template: `VideoScreen`,
    id: `2.1.2.8-video-2`,
    data: {
      double: true,
    },
  },
  {
    template: `YesNoCardsScreen`,
    id: `2.1.2.9-talking-about-it-yes-no-cards`,
    data: {
      popUp: {
        type: `feedback`,
        heading: `Feedback`,
        msg1: `<h2>Feedback</h2><p>It sounds like there are some
        dangerous things happening in your relationship. It is urgent that you
        talk to someone about what’s going on. The next screen will suggest
        some people you might like to speak with.</p>`,
        msg2: `<h2>Feedback</h2><p>It might not be urgent for you
        to get help right now, but talking to someone could really help.
        Remember, things can sometimes change quickly in an unhealthy
        relationship. The next screen will suggest some people you might like
        to speak with.</p>`,
      },
      textLeft: `<h2>Talking About It</h2>
        <p>It can be difficult to work out whether you should talk to someone about what is happening in your relationship. You might not know who to talk to first. You might feel like you can handle this yourself.</p>
        <p>The next exercise tries to give a sense of how bad things are in your relationship and how urgent it is for you to talk to someone.</p>
        `,
      cardsData: TalkingAboutItYesNoCardsData,
    },
  },
  {
    template: `CheckboxScreen`,
    id: `2.1.2.9-talking-about-it-checkbox`,
    data: {
      heading: `Talking about it`,
      textRight: `<p>Here is a list of people you might choose to talk to about problems in your relationship. Tick the boxes below to find out how these people or services can help.</p>
      <span>Please choose all the options that apply.</span>`,
      internationalPopUp: true,
      columns: 2,
      options: TwoColumnCheckboxScreenOptions,
    },
  },
  {
    template: `TalkingFeedbackScreen`,
    id: `2.1.2.9-talking-about-it-feedback`,
    data: {
      sections: [
        {
          title: `General Practitioner`,
          body: `
          <p>
        You might already have a relationship with a GP that you see
        regularly, or you may not have a regular GP. You may be worried that
        they will tell someone about what you spoke about, especially if
        they also treat the person you are in a relationship with.
      </p>
      <p>
        As a doctor, GPs are bound by confidentiality, and won’t tell anyone
        about your conversation, unless you want them to. If you have
        children however, they are mandated by law to report harm to
        children to the authorities, but they should talk to you about this
        first.
      </p>

      <p>
        They may ask you about your relationship, or you may choose to bring
        it up with them. They will be able to offer you resources that you
        can contact if you want some help, such as domestic violence
        services. They can also help you talk through a safety plan if you
        are feeling unsafe at home. If you have access to a Medicare card,
        they can also refer you to mental health services as well.
      </p>`,
        },

        {
          title: `Friends or family`,

          body: `
        <p>You may be worried about how friends or family will react if you tell them what is going on in your relationship. Fear of being judged is a common reason why people don’t tell family or friends about an abusive relationship.</p>
        <p>However, if you have a good, trusting relationship, it can be a huge relief to tell them. They will probably just want to help you in any way they can, because they care about you. It’s important to remind them to respect your decisions and be clear about how you want them to help. For example, by offering you a place to stay if things get bad at home, or just listening to you talk without trying to “fix” or “solve” the problem.</p>
        <p>For friends who are students at The University of Melbourne, there is information on this website about how they can support you.</p>
        `,
        },
        {
          title: `Police`,
          body: `
        <p>There are two ways you can report to police if your partner is violent. The first is by calling Triple Zero (000) during an emergency. If English isn’t your first language, tell the Triple Zero call taker what language you speak and they will connect you with an interpreter. Police will send officers out to the incident to assist you.</p>
        <p>When police attend, they will typically conduct a risk assessment, make sure everybody is safe, and may speak with you separately from your partner. If you have children they may talk to them as well. A police offer may ask about the relationship and if there has been any previous violence.</p>
        <p>The second way to report is by going to a police station. In either case, the police can help in a variety of ways. If a crime has been committed, they may investigate and press charges against your partner. They can issue a Family Violence Safety Notice on the spot or apply for an Intervention Order on your behalf (so that your partner cannot contact you).</p>
        `,
        },
        {
          title: `Uni counsellor`,
          body: `
        <p>The university provides free, confidential, short term counselling to currently enrolled domestic and international students. For international students especially, this may be the most easily accessible person you can talk to, because it is on campus. You can either call to book an appointment or book online.</p>
        <p>A counsellor or psychologist is a great person to talk to if you are unsure about your relationship and whether it is healthy or not as they are impartial and confidential. They will not tell the university about any issues you may be having, this is not their role. They may recommend that you seek more specialised services if they feel that you are in danger or you need some help. They will be able to help you access these services while you are in their office if you feel unsafe doing this on your own. Click on the link below to book an appointment online.</p>
        <p><a rel='noopener noreferrer' target='_blank' href='https://services.unimelb.edu.au/counsel/home#appointments'>Book an appointment online.</a></p>`,
        },
        {
          title: `Domestic violence service`,
          body: `
        <p>A good first point of contact for anyone experiencing violence in a relationship is 1800-RESPECT. It’s a confidential, 24-hour telephone service that can help you figure out what to do next. You don’t have to give your name if you don’t want to, and they won’t tell anyone what you disclose to them. This <a rel='noopener noreferrer' target='_blank' href='https://www.1800respect.org.au/help-and-support/telephone-and-online-counselling/'>link</a> provides more detailed information about what will happen if you call or chat online.</p>
        <p>Anyone can call 1800-RESPECT, whether you identify as female, male or non-binary. There are several other domestic violence services in the Melbourne area. They are confidential, safe, and free. You might worry that if you go to one of these services you will be forced to “do something” about the abuse (e.g. leave your partner, go to police), but this is not the case. They will work with you to take whatever action you are comfortable with. There is a list of these services at the end of this website.</p>`,
        },
        {
          title: `E-Safety Commissioner`,
          body: `
        <p>The eSafety commissioner is a resource that you can use when someone posts a naked picture or video of you without your consent. The eSafety Commissioner will investigate the report straight away and you will get a response from them in 48 hours or less. They may be able to get the image taken down for you and to help look after your safety. There are also fact sheets about how they investigate and where else you can get help and support anonymously.</p>
        <p> The positive to using the website is that if you don’t feel comfortable talking to someone face to face or over the phone, this may be a better option. There are links to the website of the eSafety Commissioner at the end of this website.</p>
        `,
        },
        {
          title: `Safer Community Program`,
          body: `
          <p>The Safer Community Program is the University's support service for people who have experienced inappropriate, concerning or threatening behaviour, including violence perpetrated by a partner. They are the University’s central point of inquiry and reporting and offer a respectful and confidential place to seek support and advice.</p>
          <p>You can connect with the Safer Community Program in different ways: via email, telephone, or using the appointment system (see <a rel="noopener noreferrer" target="_blank" href="https://safercommunity.unimelb.edu.au/about-us#contact">here</a> for contact options). You may also be referred to the Safer Community Program from other services within the University. For after-hours support, you can contact University security, who will refer you to the on-call well-being officer if necessary.</p>
          <p>The Safer Community Program provides services to students across all campuses of the University. At present, face-to-face services have ceased due to COVID-19, however, normally, they can organize to meet with you in person at your location.</p>
          <p>When you contact the Safer Community Program, in most instances you will have a conversation with an advisor. This is to help you decide what the appropriate response might be for your particular circumstances. Advisors are trained to respond to people experiencing abuse in relationships, and you can choose whether to speak with a male or female advisor. The conversation may cover topics like: your safety, your support requirements, and what referrals might help you. The advisor will also discuss whether you wish to make a report to Victoria Police or to the University. They can assist you in navigating the courts and complaints process and help with academic or practical support (e.g. special consideration for your studies, housing, financial help).</p>
          <p>The Safer Community Program respects your agency and decision-making and will be guided by your wishes wherever possible (as long as there are no legal requirements or University policies preventing this). The Safer Community Program will continue to engage with you until you are linked in with University or external agencies and are feeling confident to engage with formal reporting processes.</p>
        `,
        },
        {
          title: `No one or not sure`,
          body: `
        <p>You may not want to tell anyone about what is going on in your relationship. This is your right, and only you know what is best. However, studies do show that telling someone who can provide non-judgemental support can be very beneficial.</p>
        <p>Being in an unhealthy relationship can be emotionally exhausting, stressful, and sometimes frightening. You may not realise how bad things really are until you talk to someone. If none of the options listed here seem right to you, think about whether there is anyone else in your life who you might like to talk to.</p>
        <p>In the meantime, it might be helpful to keep a journal. Sometimes, the first person you need to tell is yourself, and writing things down might help. Remember to be kind to yourself and know that there are people and supports who will respond when you are ready.</p>
        `,
        },
      ],
    },
  },
  {
    template: `UnansweredQuestionsScreen`,
    data: {},
  },
]
