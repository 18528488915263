const sliderOptions = [`Very difficult`, `Somewhat difficult`, `Easy`]

const sliderCardsData = [
  {
    primaryText: `How unhealthy do you think your relationship is with your partner or ex-partner?`,
    options: sliderOptions,
  },
  {
    primaryText: `How unsafe do you fell in your relationship with your partner or ex-partner?`,
    options: sliderOptions,
  },
  {
    primaryText: `How afraid do you feel in your relationship with your partner or ex-partner?`,
    options: sliderOptions,
  },
]

const ThreeColumnCheckboxScreenOptions = [
  `Grabbing or touching someone in a sexual way when they didn’t want to be.`,
  `A person thinking they were agreeing to one thing during sex, but something else ending up happening that they didn’t agree to.`,
  `One person starting off wanting to have sex and changing their mind, but the other person keeps on going anyway.`,
  `Pressuring or blackmailing someone into having sex when they didn’t want to.`,
  `Having sex when one person is in a position of power over the other (e.g. teacher / student) and saying or implying that something bad would happen if they said no (e.g. failing an assignment).`,
  `Offering someone a place to stay or a job in exchange for sex.`,
]

export const perpetratorSexFlowData = [
  {
    template: `ContentScreen`,
    id: `2.2.1-understanding-consent`,

    data: {
      textLeft: `<h2>Understanding consent</h2>
             <p>Legally, any unwanted behaviour of a sexual nature that causes fear, humiliation or intimidation is considered to be <span>sexual assault</span>. In other words, if someone does something to you that you didn’t or couldn’t consent to (provide free agreement), it’s a crime. Sexual assault includess:</p>
             <ul>
             <li>Rape (with or without physical violence);</li>
             <li>Forcing someone to have oral sex (give or receive);</li>
             <li>Penetrating someone with fingers or other objects;</li>
             <li>Any other uninvited sexual behaviour that makes you feel uncomfortable, harassed or afraid.</li>
            </ul>
            <p>A person can’t give free agreement if they're being threatened, or if they’re asleep, drunk, or affected by substances.</p>
             `,
      textRight: `<p>We often hear about ‘No means no!’ but sometimes consent is complicated. Sometimes people might feel like they can’t say no, or that saying no would be unsafe, so instead they say nothing.</p><p>Silence is not <span>the same as consent</span>. Just because someone didn’t say ‘No’ or didn’t fight back doesn’t mean they consented.</p>
             <p>It’s very important to make sure that the person you’re having sex with is okay with everything that is happening (and that you are too!)</p>
             `,
    },
  },
  {
    template: `CheckboxScreen`,
    id: `2.2.1-unwanted-sexual-experiences-checkbox`,
    data: {
      heading: `Unwanted sexual experiences`,
      popUp: {
        type: `feedback`,
        heading: `Feedback`,
        msg1: `<h2>Answer</h2>
        <p>Actually, ALL of these experiences are forms of sexual assault. It is <span>never OK</span> to touch someone in a sexual way without consent, and it is <span>never OK</span> to pressure someone into having sex. Using someone’s situation as a way to force them to have sex is called <span>coercion</span> and it’s illegal.</p>`,
      },
      textRight: `<p>Most people hear the words ‘sexual assault’ and think of rape and physically forcing someone or holding them down. However, there are also lots of grey areas when it comes to having sex. Below are some common things that can happen.</p>
               <span>Which ones do you think count as sexual assault?</span>
               `,
      columns: 3,
      options: ThreeColumnCheckboxScreenOptions,
    },
  },
  {
    template: `CenteredContentScreen`,
    id: `2.2.1.1-attitudes`,

    data: {
      heading: `Attitudes`,
      body1: `
                 <p>Our attitudes play a big role in shaping how we behave towards others. These attitudes are learned from our parents, our peers, the media/advertising, and others we see around us. Sometimes it is good to think about our attitudes towards particular groups of people and whether we are buying into stereotypes.</p>
                 <p>For example:</p>
                 <ul>
                 <li>Women say ‘No’ to sex but really mean ‘Yes’</li>
                 <li>A woman should ‘put out’ for a man if he buys her dinner or pays for a date</li>
                 <li>Bisexual people must be slutty because they are attracted to both sexes</li>
                 <li>Transgender people are all sex workers so they must want sex all the time</li>
                 <li>All men need sex</li>
                 </ul>
                 
                `,
      body2: `<p>All of these statements represent common beliefs, but none of them are based in reality. Rather than relying on assumptions, put yourself in the other person’s shoes and ask yourself “How would I feel in this situation?”</p>`,
    },
  },
  {
    template: `CenteredContentScreen`,
    id: `2.2.1.2-healthy-sex`,
    data: {
      heading: `Healthy sex`,
      body1: `<p>When it comes to sex, it’s important to try and have a healthy and respectful attitude towards yourself and others. Sometimes other things in our lives make this difficult. For example:</p>`,
      body2: `
      <h3>Porn</h3>
<p>Lots of students watch and use porn. However, porn can create unhealthy attitudes towards sex and give unrealistic expectations of what sex should be like. For example that all women love anal sex or enjoy being hit, choked or slapped during sex. Watching too much porn can really harm your sex life. For help with this, you can visit <a rel='noopener noreferrer' target='_blank' href='www.itstimewetalked.com.au '>www.itstimewetalked.com.au</a></p>
      `,
      body3: `<h3>Drinking and drugs</h3>
     <p>Sex, drinking and drugs are not a good mix. Drinking and drug use can impair your ability to work out if someone is consenting to sex or not. It can also make you behave in ways you wouldn’t normally, and can increase or worsen your use of violence and aggression. There can be a lot of drinking at university events and it can be difficult to say ‘No’. However, making a personal rule to avoid having sex if you’re too intoxicated or high might you help avoid doing something you’ll regret later.</p>`,
      body4: `<h3>Peer pressure</h3>
     <p>Friends can sometimes pressure you into doing things you don’t want to be doing. This can include sexual harassment or assault. For information about things to say if friends are pressuring you to behave in disrespectful ways, visit <a rel='noopener noreferrer' target='_blank' href='www.theline.org.au'>www.theline.org.au</a></p>
     `,
    },
  },
  {
    template: `ContentScreen`,
    id: `2.2.1.3-feelings`,

    data: {
      textLeft: `<h2>Guilt versus Shame</h2>
                 <p>We all do things that we regret. Feeling guilty about things you have done is normal. On the other hand, feeling that your behaviour defines you as a person – and feeling shame about who you are – is not a helpful response.</p><p> It is never too late to change your actions.</p>
                `,
      textRight: `<p>There are services who can help.</p>
             <p>On the following page we will suggest some helpful things you can try.</p>`,
    },
  },
]
