const sliderOptions = [`Very difficult`, `Somewhat difficult`, `Easy`]

const sliderCardsData = [
  {
    primaryText: `How unhealthy do you think your relationship is with your partner or ex-partner?`,
    options: [
      `Very unhealthy or abusive`,
      `Somewhat unhealthy`,
      `Healthy Relationship`,
    ],
  },
  {
    primaryText: `How unsafe does your partner or ex-partner feel in their relationship with you?`,
    options: [`Very unsafe`, `Somewhat unsafe`, `Safe`],
  },
  {
    primaryText: `How afraid does your partner or ex-partner feel in their relationship with you?`,
    options: [`Very afraid`, `Somewhat afraid`, `Not afraid`],
  },
]

const healthySliderCardsData = [
  {
    primaryText: `How difficult do you find it to listen when you are angry?`,
    options: sliderOptions,
  },
  {
    primaryText: `How difficult do you find it to not blame or put down the other person in an argument?`,
    options: sliderOptions,
  },
  {
    primaryText: `How difficult do you find it to keep a calm demeanour & tone of voice/body language?`,
    options: sliderOptions,
  },
  {
    primaryText: `How difficult do you find it to weight up criticisms against you fairly?`,
    options: sliderOptions,
  },
  {
    primaryText: `How difficult do you find it to make honest apologies when appropriate – without a ‘tit-for-tat’ clause?`,
    options: sliderOptions,
  },
  {
    primaryText: `How difficult do you find it to be the one to offer the ‘olive branch’ and set about making peace, even if you feel you were in the right?`,
    options: sliderOptions,
  },
  {
    primaryText: `How difficult do you find it to be caring even when you don’t feel good?`,
    options: sliderOptions,
  },
]

const yesNoCardsData = [
  {
    primaryText: `Called your partner names or told them they’re crazy?`,
  },
  {
    primaryText: `Picked on your partner in front of their or your friends and family?`,
  },
  {
    primaryText: `Felt unhappy about your partner seeing friends or family and tried to stop that happening?`,
  },
  {
    primaryText: `Checked your partner’s phone to see who they’ve been talking to?`,
  },
  {
    primaryText: `Made your partner tell you where they are going or report back in detail on what they did and who they saw?`,
  },
  {
    primaryText: `Made sure that you make the rules in your relationship and are in charge of enforcing them?`,
  },
  {
    primaryText: `Made sure that you control the money and bills and told your partner you don’t trust them to manage this properly?`,
  },
  {
    primaryText: `Tried to scare your partner on purpose or used threats to get them to do something you wanted them to do / not do?`,
  },
  {
    primaryText: `Caused damage to things in the house, broken or smashed items, thrown things at walls or at your partner?`,
  },
  {
    primaryText: `Taken your frustrations out on pets or animals? `,
  },
  {
    primaryText: `Used physical force against your partner?`,
  },
  {
    primaryText: `Made your partner have sex or participate in sexual activities when they didn’t want to?`,
  },
  {
    primaryText: `Put your hands around your partner’s neck, or held them against the wall or floor by the neck?`,
  },
  {
    primaryText: `Waved around or used an object (such as a branch, pole, knife or gun) to scare or harm your partner?`,
  },
]
const yesNoCardsBehavioursData = [
  {
    primaryText: `Cyberstalking?`,
  },
  {
    primaryText: `Using the internet to threaten them or their family?`,
  },
  {
    primaryText: `Checking or hacking into their social media or emails?`,
  },
  {
    primaryText: `Unwanted sexting or unsolicited ‘dick pics’?`,
  },
  {
    primaryText: `Sharing naked or sexual images of your partner online without their consent?`,
  },
]

const ThreeColumnCheckboxScreenOptions = [
  `Threatened on a digital device to physically hurt your partner’s family or friends.`,
  `Threatened on a digital device to physically hurt your partner.`,
  `Shared a nude photo of your partner without their permission.`,
  `Threatened to distribute nude images of your partner.`,
  `Threatened to distribute embarrassing information about your partner.`,
  `Threatened on a digital device to hurt yourself if your partner didn’t do what you wanted.`,
  `Took a photo or video of your partner naked or during sex without their permission.`,
  `Started a social networking page to post negative information about your partner.`,
  `Monitored where your partner was using tracking software.`,
  `Monitored your partner’s internet activity using software.`,
  `Told your partner on a digital device to hurt themselves.`,
]
const bigYesNoCardsScreenData = [
  {
    primaryText: `Body Signals`,
    secondaryText: `Examples of some things you might feel in your body during an argument:
             `,
    list: [
      `Tension in stomach`,
      `Tension in neck/shoulders`,
      `Tension in jaws`,
      `You get hot`,
      `Your breathing changes`,
      `Your heart rate goes up`,
    ],
  },
  {
    primaryText: `Behaviour Signals`,
    secondaryText: `Examples of what you might do during an argument:
             `,
    list: [
      `Point a finger`,
      `Close fists`,
      `Wave your arms around`,
      `Pace around the room`,
      `Raise your voice/yell`,
      `Glare`,
      `Sulk`,
      `Interrupt`,
      `Go quiet`,
    ],
  },
  {
    primaryText: `Mental Signals`,
    secondaryText: `Examples of what you might do during an argument:`,
    list: [
      `They're doing this to wind me up!`,
      `They’re so stupid!`,
      `They never listen to me!`,
    ],
  },
  {
    primaryText: `Emotional Signals`,
    secondaryText: `Examples of how you might feel during an argument:`,
    list: [
      `Resentful`,
      `Angry`,
      `Trapped`,
      `Controlled`,
      `Confused`,
      `Got at`,
      `Challenged`,
      `Guilty`,
      `Embarrassed`,
    ],
  },
  {
    primaryText: `Triggers`,
    secondaryText: `Triggers are things that bother you, they might include`,
    list: [
      `Money worries`,
      `Housework`,
      `Jealousy`,
      `Friends’ behaviour`,
      `Study stress`,
      `Tiredness`,
      `Certain times of day`,
      `Drinking or hungover`,
      `Hunger`,
      `Sports team loses`,
    ],
  },
]

const TwoColumnCheckboxScreenOptions = [
  `Close friend`,
  `Mentor, pastor or spiritual leader`,
  `General Practitioner / family doctor`,
  `Uni counsellor`,
  `Men's Referral Service (MRS) or other telephone helpline`,
]

export const perpetratorRelationshipFlowData = [
  {
    template: `ContentScreen`,
    id: `2.2.2-healthy-relationship`,
    data: {
      textLeft: `
      <h2>What is a healthy relationship?</h2>
      <p>Sometimes, it can be difficult to tell whether a relationship is healthy or unhealthy, especially when you don’t have anything to compare it to.</p>`,
      textRight: `
      <p>Generally, a healthy relationship involves:</<p>
     <ul>
       <li>Mutual trust</li>
       <li>Support and open communication</li>
       <li>Commitment and honesty</li>
       <li>Respect</li>
      <li>Freedom to make your own decisions</li>

      </ul>
      <p>An unhealthy relationship involves:</p>
      <ul>
      <li>Unsupportive or disrespectful behaviour</li>
      <li>Pain, fear or harm</li>
      <li>Controlling behavior</li>
      </ul>`,
    },
  },
  {
    template: `SliderCardsScreen`,
    id: `2.2.2-healthy-relationship`,
    data: {
      textLeft: `
      <h2>What is a healthy relationship?</h2>
      <p>Even if you have arguments or fights, this does not necessarily mean your relationship is unhealthy.</p>
      <p>However, when one person is feeling unsafe or afraid in the relationship, this is usually a sign that there are serious problems.</p>
      `,
      textRight: `<p>It can be helpful to assess your relationship using the scale below.</p><p class='instructions'>Drag the scale from left to right to make your selection.</p>`,
      slidersData: sliderCardsData,
    },
  },
  {
    template: `YesNoCardsScreen`,
    id: `2.2.2.1-relationship-behaviours`,
    data: {
      textLeft: `<h2>Relationship behaviours</h2>
        <p>It may be tempting to focus on your partner’s behaviour and things that they have done to you.</p>
        <p>But for the purposes of this exercise, it will be most useful if you reflect <span>only on your own behaviour</span>. Remember, your answers are confidential.</p>
        `,
      textRight: `<p>Think about your behaviour and select <span>'yes'</span> to any of the following that you have done in the past.</p`,
      cardsData: yesNoCardsData,
      popUp: {
        type: `feedback`,
        msg1: `<h2>Feedback</h2>
    <p>Unfortunately we haven't received enough information to give you any feedback on this section.</p>
            `,
      },
    },
  },
  {
    template: `ContentScreen`,
    id: `2.2.2.2-feedback-at-risk`,
    data: {
      textLeft: `<h2>Feedback</h2>
      <p>There are some risky aspects to your current behaviour. Please take a minute to consider how you might be able to reduce the negative consequences these behaviours might be having on your relationship.</p>
      <p>For men: Please consider calling Men’s Referral Services to speak to a telephone counsellor about making your situation safer on <a href='tel:1300766491'> 1300 766 491.</a>.</p>
      <p>For women and gender-diverse individuals, call 1800-RESPECT (<a href='tel:1800737732'> 1800 737 732 </a>).</p>
      <p>If you are currently feeling upset, please <a rel='noopener noreferrer' target='_blank' href='https://services.unimelb.edu.au/counsel/resources/guided-exercises'>click here</a> to take part in a grounding exercise.</p>
      `,
      textRight: `<p>REMEMBER</p>
                <p>We are talking about your <span>behaviours</span>, not you as a person. Behaviours can be changed!</p>
                `,
    },
  },
  {
    template: `ContentScreen`,
    id: `2.2.2.2-feedback-high-risk`,
    data: {
      textLeft: `<h2>Feedback</h2>
      <p>The answers you have given suggest your behaviours are putting your partner in danger. There is help available to reduce this danger.</p>
      <p>For men: Men’s Referral Service are available 24/7 and are trained to help support safety in situations such as yours. Please consider calling Men’s Referral Services to speak to a telephone counsellor about making your situation safer on <a href='tel:1300766491'>1300 766 491</a>.</p>
      <p>For women and gender-diverse individuals, call 1800-RESPECT (<a href='tel:1800737732'> 1800 737 732 </a>).</p>
      <p>If you are currently feeling upset, please <a rel='noopener noreferrer' target='_blank' href='https://services.unimelb.edu.au/counsel/resources/guided-exercises'>click here</a> to take part in a grounding exercise.</p>
      <p>If you are feeling unsafe please call Lifeline on <a styled=' white-space: nowrap;' href='tel:131114'> 13 11 14 </a>.</p>
      `,
      textRight: `<p>REMEMBER</p>
                <p>We are talking about your <span>behaviours</span>, not you as a person. Behaviours can be changed!</p>
                `,
    },
  },
  {
    template: `CenteredContentScreen`,
    id: `2.2.2.3-relationship-behaviours`,
    data: {
      heading: `Emotional Abuse`,
      body1: `
        <p>People often think that an abusive relationship involves hitting, kicking and other physical violence. But your behaviour could be abusive even if there is no physical violence at all.</p>
        <p>Emotional abuse is a non-physical type of violence that includes things like: </p>
        <ul>
        <li>Controlling behavior – telling a partner what they can and can’t do, where they can go, who they can see, how they should dress or act</li>
	<li>Putting your partner down all the time – for example calling them ugly, stupid, or worthless</li>
	<li>Using verbal aggression and intimidation</li>
	<li>Forcing your partner to show you their phone or email so you can see who has been calling, texting or emailing them</li>
	<li>Public embarrassment or deliberate criticism of them in front of friends, colleagues or family</li>
	<li>Blackmail, manipulation, or ‘freezing out’ as a way of punishing them</li>
	<li>Denying that things are happening or re-writing history (‘gaslighting’)</li>
	<li>Stalking or following them</li>
	<li>Using your partner’s visa status as a threat to get them to do what you want</li>
	<li>Trying to manipulate them into getting pregnant or having an abortion</li>
        </ul>
        `,
    },
  },
  {
    template: `ContentScreen`,
    id: `perpetrator-sex-in-relationships`,
    data: {
      textLeft: `
      <h2>Sex in relationships</h2>
      <p>Sometimes in relationships, there can be problems with sex and consent. If one partner stops listening to the other, doesn't respect their right to say "No", or believes that their own needs should come first, this can cause serious issues. Think about your own sexual behaviour in a relationship, and whether you might have done any of the things below:</p>
      <ul>
      <li>Continually pressured your partner for sex, even after they said ‘No’;</li>
      <li>Forced your partner to do something sexual that they didn’t agree to;</li>
      <li>Tried to make your partner feel guilty for not wanting sex, sulking or ‘freezing them out’;</li>
      <li>Tried to penetrate your partner while they were asleep;</li>
      <li>Threatened to hurt your partner if they didn’t have sex with you.</li>
      </ul>
      

      
      `,
      textRight: `<p>Doing any of these things may have caused your partner serious harm. All of these things are forms of sexual assault. Just because you’re in a relationship with someone, doesn’t mean you have a right to have sex with them. In a healthy, supportive relationship, partners respect each other's sexual autonomy and their right to say 'No'. If that isn't happening, you might want to talk to someone about what’s going on in your relationship.</p>`,
    },
  },
  {
    template: `ContentScreen`,
    id: `2.2.2.4-online-behaviours-pre`,
    data: {
      textLeft: `
      <h2>Online behaviours</h2>
      <p>Sometimes things happen online that can make a partner feel scared, embarrassed or unsafe.</p>`,
      textRight: `<p>This section of the website is focusing on things you might have done to a current or ex-partner online or using a digital device (e.g. phone).</p>`,
    },
  },

  {
    template: `CheckboxScreen`,
    id: `2.2.2.5-online-behaviours-checkbox`,
    data: {
      popUp: {
        tall: true,
        type: `feedback`,
        msg1: `<h2>Feedback</h2>
<p>It sounds like you may have subjected your partner to what is called “image-based abuse”.</p>
        <p>Image-based abuse happens when someone shares (or threatens to share) a naked or intimate image of someone online without their consent. It is a type of sexual violence, and it is actually illegal.</p>
        <p>The best thing to do is to remove the images as soon as you can. If you would like help with your behaviour in relationships, please call any of the confidential services listed at the end of this website.</p>
        
                `,
        msg2: `<h2>Feedback</h2>
<p>It sounds like you may have used a digital device to intimidate, harass or control a partner.</p>
               <p>You may not realise it, but this is a form of abuse, and it can be really harmful even if it’s happening online. If you’ve used a University email account or computer, you’re also in breach of the University’s ICT policy. If you would like help with your behaviour in relationships, there are confidential services who you can talk to. It’s never too late to change your behaviour.</p>
               <p>At the end of this website there will be a list of services you can contact if you wish.</p>
        `,
      },
      heading: `Online behaviours`,
      textRight: `<p>Below is a list of things that you might have done to a partner online or using a digital device. By ‘partner’ we mean someone you were in a relationship with or casually dating.</p>
      <span>Have you ever (tick any that apply):</span>`,
      columns: 3,
      options: ThreeColumnCheckboxScreenOptions,
    },
  },
  {
    template: `ContentScreen`,
    id: `2.2.2.7-healthy-communication`,
    data: {
      textLeft: `<h2>Healthy communication</h2>
                 <p>Think of a time when you had an argument with someone.</p>`,
      textRight: `<p>For example:</p>
             <ul>
              <li>Conflict with someone at work</li>
              <li>Recent argument with partner</li>
              <li>Disagreement with a friend</li>
             </ul>
             `,
    },
  },
  {
    template: `SliderCardsScreen`,
    id: `2.2.2.7-workSliders`,
    data: {
      textLeft: `
      <h2>Healthy communication at work</h2>
      `,
      textRight: `<p>Think about a <span>conflict at work</span> and rate the following statements.</p>
                  <p class='instructions'>Drag the scale from left to right to make your selection.</p>
                  `,
      slidersData: healthySliderCardsData,
    },
  },
  {
    template: `SliderCardsScreen`,
    id: `2.2.2.7-partnerSliders`,
    data: {
      textLeft: `
      <h2>Healthy communication with a partner</h2>
      `,
      textRight: `<p>Think about a <span>disagreement with a partner (or ex-partner)</span> and rate the following statements.</p>
              <p class='instructions'>Drag the scale from left to right to make your selection.</p>
              `,
      slidersData: healthySliderCardsData,
    },
  },
  {
    template: `SliderCardsScreen`,
    id: `2.2.2.7-friendSliders`,
    data: {
      textLeft: `<h2>Healthy communication with a friend</h2>
      `,
      textRight: `<p>Think about a <span>disagreement with a friend</span> and rate the following statements.</p>
             <p class='instructions'>Drag the scale from left to right to make your selection.</p>
             `,
      slidersData: healthySliderCardsData,
    },
  },
  {
    template: `FeedbackGraphicScreen`,
    id: `2.2.2.7-healthy-communication-feedback`,
    data: {
      heading: `Feedback`,
    },
  },
  {
    template: `BigYesNoCardsScreen`,
    id: `2.2.2.8-feelings-Checkbox`,
    data: {
      textLeft: `<h2>Feelings</h2>
             <p>Let’s focus on an argument you have had with your partner. Have you felt any of these signals?</p>`,
      highCards: true,
      cardsData: bigYesNoCardsScreenData,
    },
  },
  {
    template: `VideoScreen`,
    id: `2.2.2.8-video`,
    data: {
      signals: true,
      donwloadFile: true,
    },
  },
  {
    template: `ContentScreen`,
    id: `2.2.2.8-feelings`,

    data: {
      textLeft: `<h2>Guilt versus Shame</h2>
                 <p>We all do things that we regret.</p>
                 <p>It’s important to remember that being abusive in a relationship is a choice, but you can also make a choice to change your behaviour.</p>
                `,
      textRight: `<p>Feeling guilty about things you have done is normal. On the other hand, feeling that your behaviour defines you as a person – and feeling shame about who you are – is not a helpful response.</p>
                 <p>It is never too late to change your actions. There are services who can help. A bit later in this website we will suggest some helpful things you can try.</p>`,
    },
  },
  {
    template: `CheckboxScreen`,
    id: `2.2.2.9-talking-about-it-checkbox`,
    data: {
      heading: `Talking about it`,
      textRight: `<p>Here is a list of people you might choose to talk to after an unwanted sexual experience or sexual assault. Tick the boxes below to find out how these people or services can help</p>
      <span>Please choose all the options that apply.</span>`,
      columns: 2,
      options: TwoColumnCheckboxScreenOptions,
    },
  },
  {
    template: `TalkingFeedbackScreen`,
    id: `2.2.2.8-feelings`,
    data: {
      sections: [
        {
          title: `Close friend`,
          body: `
          <p>Sometimes chatting with a friend can be a good way to work out what's going on in your relationship.</p>
          <p>Sometimes people who use violence think that this is a normal way to behave in relationships, especially if there was violence in their own family as a child.</p>
          <p> Talking to a friend can help you to see that it's not healthy or normal to use violence in a relationship, and that help is out there. Your friends can help you to be more aware of when you are starting to get agitated, and remind you to take a time out before things get out of control.</p>
          <p>If you want them to, they could also help you to stick to other goals you make to help you stay in control (e.g. drinking less, staying off drugs).</p>
      `,
        },

        {
          title: `Mentor, pastor or spiritual leader`,

          body: `
        <p>People who are mentors or leaders in your life can be good to talk to about your behaviour in relationships.</p>
        <p>Someone who remembers what it's like to be student and knows how difficult relationships can be might be helpful.</p>
        <p>A mentor can give you feedback on how your behaviour in your relationship might be affecting your partner, and may have good advice about what to do next.</p>
        <p>They may not know about specific services who can help, but they should be able to help you work out whether there's a problem and what needs to change in your behaviour.</p>
        `,
        },
        {
          title: `General Practitioner / family doctor`,
          body: `
        <p>A trusted GP can be a good person to talk to if you're worried about your behaviour in relationships.</p>
        <p>They can provide an objective opinion on your behaviour and help you to talk through what you might like to do to change.</p>
        <p>They can provide referrals and information, as well as helping with any other issues like drug or alcohol abuse.</p>
        <p>The GP must keep everything you say confidential, and will not report anything you say unless you disclose that a child is in danger of being harmed.</p>
        `,
        },
        {
          title: `Uni counsellor`,
          body: `
        <p>The university provides free, confidential, short term counselling to currently enrolled domestic and international students.</p>
        <p>For international students especially, this may be the most easily accessible person you can talk to, because it is on campus.</p>
        <p>The counselling service may be able to help you figure out what is going on in your relationship, and what you might want to do to change your behaviour.</p>`,
        },
        {
          title: `Men's Referral Service (MRS) or other telephone helpline`,
          body: `
        <p>If you identify as male and are worried about your behaviour in a relationship, the confidential Men's Referral Service can help.</p>
        <p>Their telephone service is available Monday to Friday between 8am and 9pm and 9am to 5pm on weekends. They also have a live chat function on their website.</p>
        <p>The Men's Referral Service is for men who are ready to take responsibility for their actions and talk to someone about changing their behaviour.</p>
        <p>They provide counselling, information and referral to programs and support groups. Their details are at the end of this website.</p>
        <p>For women or non-binary individuals who are worried about their behaviour, Relationships Australia or <a href='tel:1800737732'>1800-RESPECT</a> are good places to get help over the telephone.</p>`,
        },
      ],
    },
  },
]
